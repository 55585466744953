<template>
  <div>
    <b-card
      no-body
      class="saj-title"
      style=""
    >
      <validation-observer ref="validateSubcore">
        <b-card-header class="p-1 justify-content-center">
          <h5
            class="saj-header "
            style=""
          >
            {{ isUpdate ? $t("Edit Subcore") : $t("Add New Subcore") }}
            <!-- {{ subcoreInfo }} -->
            <!-- ni{{ coreData }} -->
            <!-- ini{{ coreId }} -->

          </h5>
        </b-card-header>
        <b-card-body>
          <div class="col-lg">
            <div
              class="card"
              style="
            "
            >

              <!-- Subcore Title -->

              <b-row
                class="p-1 mb-2"
                style="
                          color: black;
                          background: #f7f7fc;
                          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                          border-radius: 15px;
                          height: 100%;
                        "
              >
                {{ $t("Subcore Title") }}

                <b-row class="col-12 saj-text p-1 m-0">
                  <!-- Subcore English -->
                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-center"
                  >
                    {{ $t("In English") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('In English')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="subcoreInfo.name_bi"
                        class="saj-text"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- Subcore Malay -->
                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-center"
                  >
                    {{ $t("In Malay") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1 "
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('In Malay')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="subcoreInfo.name_bm"
                        class="saj-text"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-row>

              <!-- Subcore Title End-->

              <!-- Description -->
              <validation-observer ref="validateSubcore">
                <b-row
                  class="p-1 mb-2"
                  style="
                          color: black;
                          background: #f7f7fc;
                          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                          border-radius: 15px;
                          height: 100%;
                        "
                >
                  {{ $t("Description") }}
                  <b-row class="col-12 saj-text p-1 m-0">
                    <!-- Subcore English -->
                    <b-col
                      cols="2"
                      class="p-1 d-flex align-items-start"
                    >
                      {{ $t("In English") }}:
                    </b-col>
                    <b-col
                      cols="10"
                      class="p-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('In English')"
                        rules="required"
                      >
                        <textarea
                          v-model="subcoreInfo.description_bi"
                          class="saj-text form-control flex-grow-1"
                          name="textarea"
                          style="height: 120px
                                  border: 1px solid #d8d6de;
                                  "
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <!-- Subcore Malay -->
                    <b-col
                      cols="2"
                      class="p-1 d-flex align-items-center"
                    >
                      {{ $t("In Malay") }}:
                    </b-col>
                    <b-col
                      cols="10"
                      class="p-1 "
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('In Malay')"
                        rules="required"
                      >
                        <textarea
                          v-model="subcoreInfo.description_bm"
                          class="saj-text form-control flex-grow-1"
                          name="textarea"
                          style="height: 120px
                                  border: 1px solid #d8d6de;
                                  "
                          rows="5"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-row>
                <!-- Description End-->

                <!-- Visibility -->
                <b-row
                  class="p-1"
                  style="
                          color: black;
                          background: #f7f7fc;
                          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                          border-radius: 15px;
                          height: 100%;
                        "
                >
                  {{ $t("Visibility") }}
                  <b-row class="col-12 saj-text p-1 m-0">
                    <b-col
                      cols="2"
                      class="p-1 d-flex align-items-center"
                    >
                      {{ $t("Subsidiary") }}:
                    </b-col>
                    <b-col
                      cols="10"
                      class="p-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Subsidiary')"
                        rules="required"
                      >
                        <!-- {{ selectVisibility }} -->
                        <v-select
                          v-model="selectVisibility"
                          class="saj-title select-size-md"
                          style="background: white; "
                          label="text"
                          :placeholder="$t('Select Subsidiary')"
                          multiple
                          :reduce="val => val.value"
                          :options="subList"
                          :value="subList"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <!-- <b-col v-else>
                      <div
                        class="pb-1 pl-1"
                        style="   background-color: #fff;
                                  background-clip: padding-box;
                                  border: 1px solid #d8d6de;
                                  border-radius: 0.357rem;
                              "
                      >
                        <b-badge
                          v-for="(x, idx) in selectVisibility"
                          :key="idx"
                          variant="primary"
                          class="mr-1 mt-1"
                        >
                          {{ x.text }}
                        </b-badge>
                      </div></b-col> -->
                  </b-row></b-row></validation-observer>

              <!-- Visibility End -->
              <div
                class="d-flex justify-content-center mt-2"
                style=""
              >
                <button
                  class="btn btn-close m-1 saj-button"
                  aria-label="Close modal"
                  style="

                  color: white;
                  background: #ff0000;
                "
                  @click="close()"
                >
                  {{ $t('Cancel') }}
                </button>
                <button
                  class="btn m-1 saj-button"
                  aria-label="submit modal"
                  style="

                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
                  @click="isUpdate ? editSubcore() : addSubcore()"
                >
                  {{ $t('Save') }}
                </button>
              </div>
            </div></div></b-card-body></validation-observer></b-card></div>

</template>
<style>
</style>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  // BBadge,

} from "bootstrap-vue"
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  props: {

    coreData: {
      type: null,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: true,
    },
    coreId: {
      type: Number,
      default: null,
    },

  },
  data() {
    return {
      required,
      // subcore_english: null,
      // subcore_malay: null,
      // desc_english: null,
      // desc_malay: null,
      selectVisibility: [],
      subList: [],
      subcoreInfo: [],
      subsidiaryIdList: [],
    }
  },
  mounted() {
    this.getSubsidiaryAll()
    if (this.isUpdate){
      this.getSubInfo()
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    addSubcore(){
      this.$refs.validateSubcore.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('competency_core_id', this.coreId)
          data.append('name_bm', this.subcoreInfo.name_bm)
          data.append('name_bi', this.subcoreInfo.name_bi)
          data.append('description_bm', this.subcoreInfo.description_bm)
          data.append('description_bi', this.subcoreInfo.description_bi)
          this.selectVisibility.forEach(sub => {
            data.append('visible_to_subsidiary_id[]', sub)
          })
          // data.append('visible_to_subsidiary_id[]', this.selectVisibility)

          // data.forEach(a => {
          //   console.log("append add", a)
          // })
          // console.log(this.isUpdate)
          this.$axios.post(`${this.$baseUrl}/competency_subcore/create_subcores_content`, data)
            .then(() => {
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Subcore successfully added')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                this.$emit('reload-subcore')
                this.$emit('close')
              }, 1000)
            })
        }
      })
    },
    editSubcore(){
      this.$refs.validateSubcore.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('subcore_id', this.subcoreInfo.id)
          data.append('name_bm', this.subcoreInfo.name_bm)
          data.append('name_bi', this.subcoreInfo.name_bi)
          data.append('description_bm', this.subcoreInfo.description_bm)
          data.append('description_bi', this.subcoreInfo.description_bi)
          // data.append('visible_to_subsidiary_id[]', this.selectVisibility)
          this.selectVisibility.forEach(sub => {
            data.append('visible_to_subsidiary_id[]', sub)
          })

          // data.forEach(a => {
          //   console.log("append edit", a)
          // })
          // console.log(this.isUpdate)
          this.$axios.post(`${this.$baseUrl}/competency_subcore/update_subcores_content`, data)
            .then(() => {
              // data.forEach(a => {
              //   console.log("append", a)
              // })
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Subcore successfully updated')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                this.$emit('reload-subcore')
                this.$emit('close')
              }, 1000)
            })
        }
      })
    },
    getSubsidiaryAll() {
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allSubsideriesName = response.data.data.subsidiaries
        this.allSubsideriesName.forEach(nameSub => {
          this.subList.push({
            value: nameSub.id,
            text: nameSub.subsidiary_name,
          })
        })
      })
    },
    getSubInfo(){
      this.$axios.get(`${this.$baseUrl}/competency_subcore/get_subcores_by_id?subcore_id=${this.coreData.item.id}`).then(response => {
        this.subcoreInfo = response.data.data
        this.selectVisibility = this.subcoreInfo.visible_to_subsidiary_id
        this.subsidiaryIdList = this.subcoreInfo.map(sub => (
          sub.visible_to_subsidiary_id
        ))

        // console.log('subsidiary id', this.subsidiaryIdList)
        if (this.isUpdate){
          setTimeout(() => {
            this.visibleSub()
          }, 500)
        }
      })
    },
    visibleSub(){
      this.selectVisibility = this.subList.filter(item => this.selectVisibility.includes(item.value))
    },
  },
}
</script>
