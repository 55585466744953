var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"saj-title",attrs:{"no-body":""}},[_c('validation-observer',{ref:"validateSubcore"},[_c('b-card-header',{staticClass:"p-1 justify-content-center"},[_c('h5',{staticClass:"saj-header "},[_vm._v(" "+_vm._s(_vm.isUpdate ? _vm.$t("Edit Subcore") : _vm.$t("Add New Subcore"))+" ")])]),_c('b-card-body',[_c('div',{staticClass:"col-lg"},[_c('div',{staticClass:"card",staticStyle:{}},[_c('b-row',{staticClass:"p-1 mb-2",staticStyle:{"color":"black","background":"#f7f7fc","box-shadow":"0px 4px 4px rgba(0, 0, 0, 0.25),","border-radius":"15px","height":"100%"}},[_vm._v(" "+_vm._s(_vm.$t("Subcore Title"))+" "),_c('b-row',{staticClass:"col-12 saj-text p-1 m-0"},[_c('b-col',{staticClass:"p-1 d-flex align-items-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("In English"))+": ")]),_c('b-col',{staticClass:"p-1",attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"name":_vm.$t('In English'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"saj-text",attrs:{"type":"text","placeholder":""},model:{value:(_vm.subcoreInfo.name_bi),callback:function ($$v) {_vm.$set(_vm.subcoreInfo, "name_bi", $$v)},expression:"subcoreInfo.name_bi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"p-1 d-flex align-items-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("In Malay"))+": ")]),_c('b-col',{staticClass:"p-1 ",attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"name":_vm.$t('In Malay'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"saj-text",attrs:{"type":"text","placeholder":""},model:{value:(_vm.subcoreInfo.name_bm),callback:function ($$v) {_vm.$set(_vm.subcoreInfo, "name_bm", $$v)},expression:"subcoreInfo.name_bm"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('validation-observer',{ref:"validateSubcore"},[_c('b-row',{staticClass:"p-1 mb-2",staticStyle:{"color":"black","background":"#f7f7fc","box-shadow":"0px 4px 4px rgba(0, 0, 0, 0.25),","border-radius":"15px","height":"100%"}},[_vm._v(" "+_vm._s(_vm.$t("Description"))+" "),_c('b-row',{staticClass:"col-12 saj-text p-1 m-0"},[_c('b-col',{staticClass:"p-1 d-flex align-items-start",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("In English"))+": ")]),_c('b-col',{staticClass:"p-1",attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"name":_vm.$t('In English'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.subcoreInfo.description_bi),expression:"subcoreInfo.description_bi"}],staticClass:"saj-text form-control flex-grow-1",staticStyle:{"height":"120px"},attrs:{"name":"textarea","rows":"5"},domProps:{"value":(_vm.subcoreInfo.description_bi)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.subcoreInfo, "description_bi", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"p-1 d-flex align-items-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("In Malay"))+": ")]),_c('b-col',{staticClass:"p-1 ",attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"name":_vm.$t('In Malay'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.subcoreInfo.description_bm),expression:"subcoreInfo.description_bm"}],staticClass:"saj-text form-control flex-grow-1",staticStyle:{"height":"120px"},attrs:{"name":"textarea","rows":"5"},domProps:{"value":(_vm.subcoreInfo.description_bm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.subcoreInfo, "description_bm", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"p-1",staticStyle:{"color":"black","background":"#f7f7fc","box-shadow":"0px 4px 4px rgba(0, 0, 0, 0.25),","border-radius":"15px","height":"100%"}},[_vm._v(" "+_vm._s(_vm.$t("Visibility"))+" "),_c('b-row',{staticClass:"col-12 saj-text p-1 m-0"},[_c('b-col',{staticClass:"p-1 d-flex align-items-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t("Subsidiary"))+": ")]),_c('b-col',{staticClass:"p-1",attrs:{"cols":"10"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Subsidiary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"saj-title select-size-md",staticStyle:{"background":"white"},attrs:{"label":"text","placeholder":_vm.$t('Select Subsidiary'),"multiple":"","reduce":function (val) { return val.value; },"options":_vm.subList,"value":_vm.subList},model:{value:(_vm.selectVisibility),callback:function ($$v) {_vm.selectVisibility=$$v},expression:"selectVisibility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('button',{staticClass:"btn btn-close m-1 saj-button",staticStyle:{"color":"white","background":"#ff0000"},attrs:{"aria-label":"Close modal"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('button',{staticClass:"btn m-1 saj-button",staticStyle:{"color":"white","background":"#0b103c !important","border-color":"#0b103c !important"},attrs:{"aria-label":"submit modal"},on:{"click":function($event){_vm.isUpdate ? _vm.editSubcore() : _vm.addSubcore()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }