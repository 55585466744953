<template>
  <div class="">
    <div class="container">

      <div
        v-if="roles.isAdmin"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="primary"
          class="saj-button"
          @click="$bvModal.show('new-subcore'), getCoreId(), getSubcore()"
        >
          {{ $t('Add New Subcore') }}
        </b-button>
      </div>

      <div>
        <b-table
          show-empty
          :items="competencyList"
          :fields="fields"
          responsive=""
          bordered
          class="mt-1 mb-1 saj-subtitle"
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ $t(data.label) }}</span>
          </template>
          <template #cell(index)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ data.index + 1 }}
            </div>
          </template>
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>
          <template #cell(subcore)="data">
            {{ isEnglish ? data.item.subcore_bi : data.item.subcore_bm }}
          </template>
          <template #cell(description)="data">
            {{ isEnglish ? data.item.description_bi : data.item.description_bm }}
          </template>
          <template #cell(action)="data">
            <div
              class="d-flex justify-content-center"
            >
              <div v-if="roles.isAdmin">
                <feather-icon
                  v-b-tooltip.hover.top="$t('Edit')"
                  icon="EditIcon"
                  size="25"
                  style="color: green"
                  class="mr-1"
                  @click="$bvModal.show('edit-subcore'), subcoreData = data, editSubcore(), reload()"
                />
                <feather-icon
                  v-b-tooltip.hover.top="$t('Delete')"
                  icon="Trash2Icon"
                  size="25"
                  style="color: red"
                  @click="$bvModal.show('delete-subcore'), subcoreID = data.item.id, getSubcoreId(), reload()"
                />
              </div>
              <div v-else>
                {{ $t("Not available") }}
              </div>
            </div> </template></b-table>

      </div>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
import {
  BTable,
  VBTooltip,
  BButton,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

    coreId: {
      type: Number,
      default: null,
    },
    subsId: {
      type: Number,
      default: null,
    }

  },
  data() {
    return {
      subcoreData: null,
      competencyList: [
        {
          subcore: 'Subcore 1',
          description: 'Subcore 1 desc',
        },
        {
          subcore: 'Subcore 2',
          description: 'Subcore 2 desc',
        },
      ],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'subcore', label: 'Subcore', thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'description', label: 'Description', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      subcoreID: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['roles']),
  },
  mounted() {
    this.getSubcore()
  },
  methods: {
    getSubcore(){
      this.$axios.get(`${this.$baseUrl}/competency_subcore/get_subcores_by_core_id?core_id=${this.coreId}&subsidiary_id=${this.subsId}`).then(response => {
        const res = response.data.data
        // console.log('List Subcore', res)

        this.competencyList = res.map(x => ({
          subcore_bi: x.name_bi,
          subcore_bm: x.name_bm,
          id: x.id,
          description_bi: x.description_bi,
          description_bm: x.description_bm,
        }))
      })
    },
    editSubcore(){
      this.$emit('emit-subcore-data', this.subcoreData)
    },
    getCoreId(){
      this.$emit('pass-id', this.coreId)
    },
    getSubcoreId(){
      this.$emit('pass-subcore-id', this.subcoreID)
    },
    // reload(){
    //   this.$emit('reload-subcore', this.getSubcore())
    // },
  },
}
</script>
